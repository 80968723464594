import React, { useState, useEffect } from 'react';

import {
  useParams,
  useLocation,
  useHistory,
  Route,
  Switch,
  type RouteComponentProps
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import type { IStoreState, HUBThunkDispatch } from '../types/store.js';

import { PAGE_SIZE } from '../actions/index.js';
import { getAccountAllEntries, getFolderEntries } from '../actions/folder.js';

import { getPrevEuid, getNextEuid, getNthEuid, getEuidIndex } from '../utils/folder.js';

import AccountFolder from './account-folder.js';

import EntryOverlay from './entry-overlay.js';
import EntryPage from './entry-page.js';
import MediaViewer from './media-viewer.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

/**
 * AccountRouter is responsible for the prev/next state
 * which either the page or overlay rely on
 */
export default function AccountRouter(): React.ReactElement {
  const { accountId, folderId, entryId } = useParams<{
    accountId: string;
    folderId?: string;
    entryId?: string;
  }>();
  const location = useLocation<{
    modal: boolean;
    folderId: string | undefined;
  }>();
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const history = useHistory();

  const [folderState] = useSelector((state: IStoreState) => [state.folder] as const);

  const dispatch = useDispatch<HUBThunkDispatch>();

  const [open, setOpen] = useState(false);
  const [viewMediaIndex, setViewMediaIndex] = useState<number | undefined>();
  const [prevEuid, setPrevEuid] = useState<string | undefined>();
  const [nextEuid, setNextEuid] = useState<string | undefined>();

  const folder = folderState.get(folderId || accountId);

  useEffect(() => {
    // the entries for folder or everything in account
    const folder = folderState.get(folderId || accountId);
    if (!folder || !folder.sparse_entries) {
      dispatch(
        folderId
          ? getFolderEntries(accountId, folderId, xCITOrigin, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
          : getAccountAllEntries(accountId, xCITOrigin, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
      );
    }

    // we never want to start in a modal state.  If the location state has modal
    // set to true then replace the current location
    if (location.state?.modal === true) {
      history.replace(location.pathname);
    }
  }, []);

  useEffect(() => {
    if (entryId && folder && folder._meta?.state === FULFILLED) {
      (async function () {
        const prevEuid = await getPrevEuid(folder, entryId, xCITOrigin, dispatch);
        const nextEuid = await getNextEuid(folder, entryId, xCITOrigin, dispatch);

        setPrevEuid(prevEuid);
        setNextEuid(nextEuid);
      })();
    }
  }, [entryId]);

  const handleShowEntry = (euid: string) => {
    setOpen(true);

    history.push(`/${accountId}/folder/${folderId ? `${folderId}/` : ''}entry/${euid}`, {
      modal: true,
      folderId
    });
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleClosed = () => {
    history.push(`/${accountId}/folder${folderId ? `/${folderId}` : ''}`);
  };

  const handleGetNthEuid = (index: number) => {
    return folder && folder._meta?.state === FULFILLED ? getNthEuid(folder, index) : undefined;
  };

  const handleGetEuidIndex = (euid: string) => {
    return folder && folder._meta?.state === FULFILLED ? getEuidIndex(folder, euid) : undefined;
  };

  const handleGetMaxIndex = () => {
    return folder ? (folder.search ? folder.search.total : folder.total) : 0;
  };

  const handleGotoEuid = (euid: string) => {
    setOpen(true);

    history.push(`/${accountId}/folder/${folderId ? `${folderId}/` : ''}entry/${euid}`, {
      modal: true,
      folderId
    });
  };

  const handleViewMedia = (euid: string, mediumIdx: number) => {
    setViewMediaIndex(mediumIdx);
  };

  const handleCloseMediaViewer = () => {
    setViewMediaIndex(undefined);
  };

  const isModal = !!location.state?.modal;

  return (
    <div>
      {entryId && viewMediaIndex !== undefined ? (
        <MediaViewer euid={entryId} mediumIdx={viewMediaIndex} onClose={handleCloseMediaViewer} />
      ) : null}

      <Switch>
        {!isModal ? (
          <Route
            path={[
              '/:accountId/folder/:folderId/entry/:entryId',
              '/:accountId/folder/entry/:entryId'
            ]}
            render={(props: RouteComponentProps) => (
              <EntryPage
                prevEuid={prevEuid}
                nextEuid={nextEuid}
                onGotoEuid={handleGotoEuid}
                onViewMedia={handleViewMedia}
                {...props}
              />
            )}
          />
        ) : null}

        <Route
          path={[
            '/:accountId/folder/:folderId/entry/:entryId',
            '/:accountId/folder/entry/:entryId',
            '/:accountId/folder/:folderId',
            '/:accountId/folder'
          ]}
          render={(props: RouteComponentProps) => (
            <AccountFolder {...props} onShowEntry={handleShowEntry} />
          )}
        />
      </Switch>

      {isModal && entryId ? (
        <Route
          exact
          path={[
            '/:accountId/folder/:folderId/entry/:entryId',
            '/:accountId/folder/entry/:entryId'
          ]}
          render={(props: RouteComponentProps<{ accountId: string; entryId: string }>) => (
            <EntryOverlay
              {...props}
              open={open}
              onRequestClose={handleRequestClose}
              onClosed={handleClosed}
              prevEuid={prevEuid}
              nextEuid={nextEuid}
              getNthEuid={handleGetNthEuid}
              getEuidIndex={handleGetEuidIndex}
              getMaxIndex={handleGetMaxIndex}
              onGotoEuid={handleGotoEuid}
              onViewMedia={handleViewMedia}
            />
          )}
        />
      ) : null}
    </div>
  );
}
