import React, { useState, useEffect } from 'react';

import {
  useHistory,
  useLocation,
  useParams,
  Route,
  Switch,
  type RouteComponentProps
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Machine, assign, type EventObject } from 'xstate';
import { useMachine } from '@xstate/react';

import { FULFILLED, REJECTED } from '@catalogit/common/lib/types/states.js';

import type { IStoreState, HUBThunkDispatch, IEntry } from '../types/store.js';

import { PAGE_SIZE } from '../actions/index.js';
import { getClassificationEntries } from '../actions/classifications.js';
import { clearClassificationSearch } from '../actions/search.js';

import { getPrevEuid, getNextEuid, getNthEuid, getEuidIndex } from '../utils/classification.js';

import Classification from './classification.js';
import EntryOverlay from './entry-overlay.js';
import EntryPage from './entry-page.js';
import MediaViewer from './media-viewer.js';

import Search from './search.js';

type ErrorOrigin = 'UNEXPECTED_ERROR' | 'CLASSIFICATION_LOAD';

interface MachineError {
  origin: ErrorOrigin;
  msg: string;
}

interface MachineContext {
  error: MachineError | undefined;
}

interface MachineEvent extends EventObject {
  error?: MachineError;
}

const stateMachine = Machine<MachineContext, MachineEvent>({
  context: {
    error: undefined
  },

  // starting start
  initial: 'start',

  // state definitions and allow transitions
  states: {
    // waits for owlSchemaClassState to be loaded before transition to load state
    start: {
      on: {
        LOAD: 'load',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    // load all the entries
    load: {
      on: {
        CONTINUE: 'ready',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    ready: {
      on: {
        CONTINUE: 'finished',
        START: 'start'
      }
    },

    error: {
      on: {
        RESTART: {
          target: 'start',
          actions: assign<MachineContext, MachineEvent>({ error: undefined })
        }
      }
    },

    finished: {
      type: 'final'
    }
  }
});

/**
 * ClassificationRouter is responsible for the prev/next state
 * which either the page or overlay rely on
 */

export interface ClassificationRouterProps {
  apiEndpoint: string;
}

export default function ClassificationRouter({
  apiEndpoint
}: ClassificationRouterProps): React.ReactElement {
  const { classificationId, entryId, query } = useParams<{
    classificationId: string;
    entryId?: string;
    query?: string;
  }>();
  const location = useLocation<{
    modal: boolean;
    back?: string;
  }>();
  const history = useHistory();

  const [classificationState] = useSelector(
    (state: IStoreState) => [state.classification] as const
  );

  const dispatch = useDispatch<HUBThunkDispatch>();

  const [current, send] = useMachine(stateMachine);
  const {
    value: currState,
    context: { error }
  } = current;

  // console.log(currState, error);

  const [open, setOpen] = useState(false);
  const [viewMediaIndex, setViewMediaIndex] = useState<number | undefined>();
  const [prevEuid, setPrevEuid] = useState<string | undefined>();
  const [nextEuid, setNextEuid] = useState<string | undefined>();

  const classification = classificationState.get(classificationId);

  useEffect(() => {
    switch (currState) {
      case 'start': {
        // the entries for folder or everything in account
        if (!classification || !classification.sparse_entries) {
          dispatch(
            getClassificationEntries(classificationId, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
          );
        }

        // we never want to start in a modal state.  If the location state has modal
        // set to true then replace the current location with one that has no state
        if (location.state?.modal === true) {
          history.replace(location.pathname, {
            ...location.state,
            modal: false
          });
        }

        send('LOAD');

        return;
      }

      case 'load': {
        if (!classification) {
          return;
        }

        switch (classification._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'CLASSIFICATION_LOAD',
                msg: 'Unexpected error loading Classifications'
              }
            });
            return;
          default:
            return;
        }

        // if the classification has an active search reset
        if (!query && classification.search) {
          dispatch(clearClassificationSearch(classification.classification));
        }

        send('CONTINUE');

        return;
      }
    }
  }, [classification, classificationId, currState, send]);

  useEffect(() => {
    send('START');
  }, [classificationId, send]);

  useEffect(() => {
    if (classification && classification._meta?.state === FULFILLED) {
      (async function () {
        const prevEuid = entryId ? await getPrevEuid(classification, entryId, dispatch) : undefined;
        const nextEuid = entryId ? await getNextEuid(classification, entryId, dispatch) : undefined;
        setPrevEuid(prevEuid);
        setNextEuid(nextEuid);
      })();
    }
  }, [classification, entryId]);

  const handleNavigateEntry = (
    accountId: string | undefined,
    folderId: string | undefined,
    euid: string
  ) => {
    history.push(`${location.pathname}/entry/${euid}`);
  };

  const handleShowEntry = (
    accountId: string | undefined,
    folderId: string | undefined,
    euid: string
  ) => {
    setOpen(true);

    history.push(`${location.pathname}/entry/${euid}`, {
      modal: true
    });
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleClosed = () => {
    if (query) {
      history.push(`/classifications/${classificationId}/search/${encodeURIComponent(query)}`);
    } else {
      history.push(`/classifications/${classificationId}`);
    }
  };

  const handleGotoEuid = (euid: string) => {
    setOpen(true);

    if (query) {
      history.push(
        `/classifications/${classificationId}/search/${encodeURIComponent(query)}/entry/${euid}`,
        {
          modal: true
        }
      );
    } else {
      history.push(`/classifications/${classificationId}/entry/${euid}`, {
        modal: true
      });
    }
  };

  const handleGetNthEuid = (index: number) => {
    return classification && classification._meta?.state === FULFILLED
      ? getNthEuid(classification, index)
      : undefined;
  };

  const handleGetEuidIndex = (euid: string) => {
    return classification && classification._meta?.state === FULFILLED
      ? getEuidIndex(classification, euid)
      : undefined;
  };

  const handleGetMaxIndex = () => {
    return classification
      ? classification.search
        ? classification.search.total
        : classification.total
      : 0;
  };

  const handleViewMedia = (euid: string, mediumIdx: number) => {
    setViewMediaIndex(mediumIdx);
  };

  const handleCloseMediaViewer = () => {
    setViewMediaIndex(undefined);
  };

  const isModal = !!location.state?.modal;

  return (
    <div>
      {entryId && viewMediaIndex !== undefined && (
        <MediaViewer euid={entryId} mediumIdx={viewMediaIndex} onClose={handleCloseMediaViewer} />
      )}

      <Switch>
        {!isModal ? (
          <Route
            path={[
              '/classifications/search/:query/entry/:entryId',
              '/classifications/:classificationId/search/:query/entry/:entryId'
            ]}
            render={(props: RouteComponentProps) => (
              <EntryPage
                prevEuid={prevEuid}
                nextEuid={nextEuid}
                onGotoEuid={handleGotoEuid}
                onViewMedia={handleViewMedia}
                {...props}
              />
            )}
          />
        ) : null}

        <Route
          path={['/classifications/search/:query?']}
          render={(props: RouteComponentProps) => (
            <Search
              {...props}
              apiEndpoint={apiEndpoint}
              onShowEntry={handleNavigateEntry}
              makeURL={(e: IEntry) =>
                query
                  ? classificationId
                    ? `/classifiations/${classificationId}/search/${encodeURIComponent(query)}`
                    : `/classifications/search/${encodeURIComponent(query)}`
                  : classificationId
                    ? `/classifiations/${classificationId}`
                    : '/classifications'
              }
            />
          )}
        />

        <Route
          path={[
            '/classifications/search/:query/entry/:entryId',
            '/classifications/:classificationId/search/:query/entry/:entryId',
            '/classifications/:classificationId/search/:query?'
          ]}
          render={(props: RouteComponentProps) => (
            <Classification {...props} onShowEntry={handleShowEntry} />
          )}
        />

        <Route
          path={[
            '/classifications/entry/:entryId',
            '/classifications/:classificationId/entry/:entryId',
            '/classifications/:classificationId',
            '/classifications'
          ]}
          render={(props: RouteComponentProps<any>) => (
            <Classification {...props} onShowEntry={handleShowEntry} />
          )}
        />

        {/* <Route
          path={['/classifications']}
          render={(props: RouteComponentProps<any>) => (
            <Classifications {...props} apiEndpoint={apiEndpoint} />
          )}
        /> */}
      </Switch>

      {isModal && entryId ? (
        <Route
          exact
          path={[
            '/classifications/search/:query/entry/:entryId',
            '/classifications/:classificationId/search/:query/entry/:entryId',
            '/classifications/:classificationId/entry/:entryId'
          ]}
          render={(
            props: RouteComponentProps<{
              classificationId?: string;
              entryId: string;
            }>
          ) => (
            <EntryOverlay
              {...props}
              open={open}
              onRequestClose={handleRequestClose}
              onClosed={handleClosed}
              prevEuid={prevEuid}
              nextEuid={nextEuid}
              getNthEuid={handleGetNthEuid}
              getEuidIndex={handleGetEuidIndex}
              getMaxIndex={handleGetMaxIndex}
              onGotoEuid={handleGotoEuid}
              onViewMedia={handleViewMedia}
            />
          )}
        />
      ) : null}
    </div>
  );
}
