import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Machine, assign, type EventObject } from 'xstate';
import { useMachine } from '@xstate/react';

import {
  InfiniteLoader,
  type InfiniteLoaderChildProps,
  WindowScroller,
  type WindowScrollerChildProps,
  AutoSizer,
  type Size,
  Grid,
  type GridCellProps
} from 'react-virtualized';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import { alpha } from '@material-ui/core/styles/colorManipulator';

import { Typography, Tooltip, CircularProgress } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';
import { FULFILLED, PENDING, REJECTED } from '@catalogit/common/lib/types/states.js';
import {
  getPublicThumbnail,
  getPlaceholderImage,
  getContrastColor
} from '@catalogit/common/lib/utils/media-utils.js';

import type { IStoreState, HUBThunkDispatch, IAction } from '../types/store.js';

import { PAGE_SIZE } from '../actions/index.js';
import { getAccount, getAccountFolders } from '../actions/account.js';
import { getAccountAllEntries, getFolderEntries } from '../actions/folder.js';
import {
  searchAcountEntries,
  clearAcountEntriesSearch,
  searchAcountFolder,
  clearAcountFolderSearch
} from '../actions/search.js';

import FolderDrawer, { NAVDRAWER_WIDTH } from '../components/folder-drawer.js';
import PageHeader, { top, paddingTop } from '../components/page-header.js';

import { hasIndex } from '../utils/folder.js';
import { getThumbnailSlotValues, getThumbnailSlotCount } from '../utils/customization.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const OVERSCAN_BY_PIXELS = 0;
const COLUMN_WIDTH = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      ...top(theme),
      backgroundColor: theme.palette.background.default
    },

    folderInfo: {
      ...paddingTop(theme),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(1)
      }
    },

    folderTitle: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
      color: 'inherit'
    },

    folderDescription: {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
      color: 'inherit'
    },

    folderClassifications: {
      margin: `0 ${theme.spacing(1)}px`
    },

    gridContent: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(1)
      },

      outline: 0
    },

    interstitial: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(2)
      }
    },

    noContent: {
      padding: theme.spacing(1),
      color: 'inherit'
    },

    gridCell: {
      padding: theme.spacing(1),
      color: 'inherit'
    },

    gridCellPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    gridCellLink: {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none',
      color: 'inherit'
    },

    gridCellImg: {
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(0)
    },

    cardSlot1: {
      whiteSpace: 'nowrap',
      color: 'inherit',
      ...textTruncate
    },

    cardSlot2: {
      fontWeight: theme.typography.fontWeightMedium as any,
      whiteSpace: 'nowrap',
      ...textTruncate
    },

    cardSlot3: {
      fontWeight: theme.typography.fontWeightLight as any,
      whiteSpace: 'nowrap',
      lineHeight: 1,
      ...textTruncate
    }
  })
);

type ErrorOrigin = 'UNEXPECTED_ERROR' | 'ACCOUNT_LOAD' | 'FOLDER_LOAD';

interface MachineError {
  origin: ErrorOrigin;
  msg: string;
}

interface MachineContext {
  error: MachineError | undefined;
}

interface MachineEvent extends EventObject {
  error?: MachineError;
}

const stateMachine = Machine<MachineContext, MachineEvent>({
  context: {
    error: undefined
  },

  // starting start
  initial: 'start',

  // state definitions and allow transitions
  states: {
    // waits for owlSchemaClassState to be loaded before transition to load state
    start: {
      on: {
        LOAD: 'load',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    // load all the entries
    load: {
      on: {
        CONTINUE: 'ready',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    ready: {
      on: {
        CONTINUE: 'finished',
        START: 'start'
      }
    },

    error: {
      on: {
        RESTART: {
          target: 'start',
          actions: assign<MachineContext, MachineEvent>({ error: undefined })
        }
      }
    },

    finished: {
      type: 'final'
    }
  }
});

interface AccountFolderProps {
  onShowEntry: (euid: string) => void;
}

export default function AccountFolder({ onShowEntry }: AccountFolderProps): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { accountId, folderId } = useParams<{
    accountId: string;
    folderId: string;
  }>();

  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const hasMountedRef = useRef(false);

  const [accountState, folderState, entryState, mediaState] = useSelector(
    (state: IStoreState) => [state.account, state.folder, state.entry, state.media] as const
  );

  const dispatch = useDispatch<HUBThunkDispatch>();

  const classes = useStyles();
  const theme = useTheme();

  const [current, send] = useMachine(stateMachine);
  const {
    value: currState,
    context: { error }
  } = current;

  // console.log(currState, error);

  const [columnCount, setColumnCount] = useState(0);
  const [columnWidth, setColumnWidth] = useState(COLUMN_WIDTH);
  const [revision, setRevision] = useState(0);
  const [search, setSearch] = useState<string>('');

  const account = accountState.get(accountId);
  const folder = folderState.get(folderId || accountId);
  const entryIds = (folder && (folder.search?.sparse_entries || folder?.sparse_entries)) || [];

  useEffect(() => {
    switch (currState) {
      case 'start': {
        // only fetch account if not already loaded
        if (!account) {
          dispatch(getAccount(accountId, xCITOrigin));
          // also fetch folders
          dispatch(getAccountFolders(accountId, xCITOrigin));
        }
        // if folders in account aren't loaded yet fetch them: either no folders or
        // one folder has an undefined total
        else if (
          folderState.size === 0 ||
          folderState.some((f) => f.account_id === accountId && f.total === undefined)
        ) {
          dispatch(getAccountFolders(accountId, xCITOrigin));
        }

        // always unconditionally fetch folder content.  Current folder content might
        // be filtered from search, stale, or non-existant; worst case is we needlessly
        // reload

        dispatch(
          folderId
            ? getFolderEntries(accountId, folderId, xCITOrigin, {
                startIndex: 0,
                stopIndex: PAGE_SIZE - 1
              })
            : getAccountAllEntries(accountId, xCITOrigin, {
                startIndex: 0,
                stopIndex: PAGE_SIZE - 1
              })
        );

        send('LOAD');

        return;
      }

      case 'load': {
        if (!account) {
          return;
        }

        switch (account._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'ACCOUNT_LOAD',
                msg: 'Unexpected error loading Account'
              }
            });
            return;
          default:
            return;
        }

        if (!folder) {
          return;
        }

        switch (folder._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'FOLDER_LOAD',
                msg: 'Unexpected error loading Folder'
              }
            });
            return;
          default:
            return;
        }

        // if the folder has an active search reset
        if (folder.search) {
          dispatch(
            folderId
              ? clearAcountFolderSearch(accountId, folderId)
              : clearAcountEntriesSearch(accountId)
          );
        }

        // reset search state
        setSearch('');

        setColumnCount(0);
        setColumnWidth(COLUMN_WIDTH);
        setRevision(revision + 1);
        window.scrollTo(0, 0);

        send('CONTINUE');

        return;
      }
    }
  }, [
    currState,
    account,
    folder,
    accountState,
    folderState,
    dispatch,
    accountId,
    send,
    folderId,
    revision
  ]);

  useEffect(() => {
    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    send('START');
  }, [accountId, folderId, send]);

  const backgroundColor = accountState.getIn([accountId, 'theme', 'backgroundColor']) as string;

  useEffect(() => {
    if (backgroundColor) {
      const hexBackgroundColor = `#${backgroundColor}`;
      window.document.body.style.backgroundColor = hexBackgroundColor;

      return () => {
        window.document.body.style.backgroundColor = '';
      };
    }
  }, [backgroundColor]);

  // This needs to be the last useEffect to avoid extra initial "on-mount" work
  useEffect(() => {
    hasMountedRef.current = true;
  }, []);

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);

    if (searchTerm) {
      dispatch(
        folderId
          ? searchAcountFolder(accountId, folderId, searchTerm, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
          : searchAcountEntries(accountId, searchTerm, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
      );
    } else
      dispatch(
        folderId
          ? clearAcountFolderSearch(accountId, folderId)
          : clearAcountEntriesSearch(accountId)
      );

    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    window.scrollTo(0, 0);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const { id } = e.currentTarget.dataset;
    if (id) {
      onShowEntry(id);
    }
  };

  // grid implementatiuon
  const onResize = ({ width }: { width: number }) => {
    const matches = window.matchMedia(`(min-width: ${theme.breakpoints.values.sm}px)`).matches;

    const gridWidth = width - (matches ? NAVDRAWER_WIDTH : 0) - theme.spacing(2);

    const newColumnCount = Math.ceil(gridWidth / COLUMN_WIDTH);

    setColumnCount(newColumnCount);
    setColumnWidth(gridWidth / newColumnCount);
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    if (!folder) {
      return false;
    }
    return hasIndex(folder, index);
  };

  const loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number;
    stopIndex: number;
  }): Promise<IAction> => {
    if (search) {
      return dispatch(
        folderId
          ? searchAcountFolder(accountId, folderId, search, {
              startIndex,
              stopIndex
            })
          : searchAcountEntries(accountId, search, { startIndex, stopIndex })
      );
    } else {
      return dispatch(
        folderId
          ? getFolderEntries(accountId, folderId, { startIndex, stopIndex })
          : getAccountAllEntries(accountId, { startIndex, stopIndex })
      );
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    if (!account || !folder) {
      return null;
    }

    const index = columnCount * rowIndex + columnIndex;

    // ensure we're not requesting more than the max entries
    const max = folder.search ? folder.search.total : folder.total;
    if (index >= max) {
      return null;
    }

    const entry = entryState.get(entryIds[index]);
    if (!entry) {
      // return a placeholder
      return (
        <div
          key={key}
          className={classes.gridCellPlaceholder}
          style={{
            ...style
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const { width } = style;
    const mediaIds = entry.media;
    const medium = mediaIds ? mediaState.get(mediaIds[0]) : undefined;
    const { path } = medium
      ? getPublicThumbnail(medium)
      : account.avatar
        ? getPublicThumbnail(account.avatar)
        : getPlaceholderImage();

    const [slot1, slot2, slot3] = getThumbnailSlotValues(account, entry);

    return (
      <div
        key={key}
        className={classes.gridCell}
        style={{
          ...style
        }}
      >
        <a
          data-id={entry.id}
          className={classes.gridCellLink}
          href={
            folderId
              ? `/${accountId}/folder/${folderId}/entry/${entry.id}`
              : `/${accountId}/folder/entry/${entry.id}`
          }
          onClick={handleClick}
        >
          <Tooltip title={slot1} placement='top' arrow aria-label={slot1}>
            <div>
              <img
                loading='lazy'
                src={path}
                className={classes.gridCellImg}
                style={{
                  height: (width as number) - theme.spacing(2),
                  width: (width as number) - theme.spacing(2)
                }}
                alt={slot1}
              />
              {slot1 ? (
                <Typography variant='subtitle2' className={classes.cardSlot1}>
                  {slot1}
                </Typography>
              ) : null}
            </div>
          </Tooltip>
          {slot2 ? (
            <Typography
              variant='caption'
              className={classes.cardSlot2}
              style={{ color: alpha(fgColor, 0.6) }}
            >
              {slot2}
            </Typography>
          ) : null}
          {slot3 ? (
            <Typography
              variant='caption'
              className={classes.cardSlot3}
              style={{ color: alpha(fgColor, 0.5) }}
            >
              {slot3}
            </Typography>
          ) : null}
        </a>
      </div>
    );
  };

  let name;
  let description;
  // let classifications
  let folderContent;

  // determine foreground & background colors
  let bgColor = theme.palette.background.default;
  let fgColor = theme.palette.text.primary;

  switch (currState) {
    case 'ready': {
      if (!account || !folder) {
        folderContent = (
          <div className={classes.interstitial}>
            Unexpected state: Loaded but missing account or folder
          </div>
        );
      } else {
        switch (folder._meta?.state) {
          case PENDING: // pending should mean we're loading more data
          case FULFILLED: {
            name = folder.name || 'All';

            if (folder.description) {
              // allowing HTML in folder description ??? TODO: revisit when rich text is supported
              description = (
                <Typography
                  variant='body2'
                  className={classes.folderDescription}
                  dangerouslySetInnerHTML={{ __html: folder.description }}
                />
              );
            }

            // if (folder.classifications) {
            //   classifications = (
            //     <Typography
            //       variant='subtitle2'
            //       className={classes.folderClassifications}
            //     >
            //       {folder.classifications
            //         .slice(0, 4)
            //         .map(({ classification }) => classification)
            //         .join(', ')}
            //     </Typography>
            //   );
            // }

            if (backgroundColor) {
              bgColor = backgroundColor;
              fgColor = getContrastColor(bgColor);

              // add # for hex
              bgColor = `#${bgColor}`;
              fgColor = `#${fgColor}`;
            }

            const entryCount = folder.search ? folder.search.total : folder.total;
            const rowCount = Math.ceil(entryCount / (columnCount || 1));

            folderContent = (
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isRowLoaded={isRowLoaded}
                rowCount={entryCount}
                loadMoreRows={loadMoreRows}
                minimumBatchSize={PAGE_SIZE}
                threshold={columnCount * 4}
              >
                {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => (
                  <AutoSizer disableHeight onResize={onResize}>
                    {({ width }: Size) => (
                      <WindowScroller overscanByPixels={OVERSCAN_BY_PIXELS} key={revision}>
                        {({
                          height,
                          scrollTop,
                          isScrolling,
                          onChildScroll
                        }: WindowScrollerChildProps) => (
                          <Grid
                            ref={registerChild}
                            estimatedColumnSize={COLUMN_WIDTH}
                            estimatedRowSize={COLUMN_WIDTH}
                            onSectionRendered={({
                              columnStartIndex,
                              columnStopIndex,
                              rowStartIndex,
                              rowStopIndex
                            }) => {
                              const startIndex = rowStartIndex * columnCount + columnStartIndex;
                              const stopIndex = rowStopIndex * columnCount + columnStopIndex;

                              onRowsRendered({
                                startIndex,
                                stopIndex
                              });
                            }}
                            className={classes.gridContent}
                            style={{ color: fgColor }}
                            cellRenderer={cellRenderer}
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            rowCount={rowCount}
                            rowHeight={
                              columnWidth + theme.spacing(1 + getThumbnailSlotCount(account) * 2)
                            }
                            width={width}
                            height={height}
                            scrollTop={scrollTop}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            autoHeight={true}
                            noContentRenderer={() =>
                              folder._meta?.state === PENDING ? (
                                <div className={classes.noContent}>Loading Entries...</div>
                              ) : (
                                <div className={classes.noContent}>No matching Entries</div>
                              )
                            }
                          />
                        )}
                      </WindowScroller>
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            );
            break;
          }
          case REJECTED: {
            folderContent = (
              <div className={classes.interstitial}>{`Error: ${
                folder._error && folder._error.message
              }`}</div>
            );
            break;
          }
        }
      }

      break;
    }
    case 'error': {
      folderContent = (
        <div className={classes.gridContent}>
          <div
            className={classes.noContent}
            style={{ color: fgColor }}
          >{`Error: ${error?.msg}`}</div>
        </div>
      );
      break;
    }
    default: {
      folderContent = (
        <div className={classes.gridContent}>
          <div className={classes.noContent} style={{ color: fgColor }}>
            Loading Entries...
          </div>
        </div>
      );
      break;
    }
  }

  const accountName = account?.name ? `${account.name} - ` : '';
  const folderName = folder?.name ? `${folder.name || 'All'} - ` : '';

  return (
    <>
      <Helmet>
        <title>{`${folderName}${accountName}CatalogIt HUB`}</title>
      </Helmet>

      {/* toolbar */}
      <PageHeader
        onSearch={handleSearch}
        backNav={`/${accountId}`}
        placeholder={`Search${
          account
            ? ` ${
                folderId && folder
                  ? folder.name || 'this folder'
                  : account.name || 'this collection'
              }`
            : ''
        }`}
        variant='normal'
      />

      {/* drawer */}
      <FolderDrawer
        accountId={accountId}
        folderId={folderId}
        baseURL={`/${accountId}`}
        accountState={accountState}
        folderState={folderState}
        selectedColor={theme.palette.common.white}
        paperClass={classes.drawerPaper}
        backgroundColor={bgColor}
      />

      <div className={classes.folderInfo} style={{ color: fgColor }}>
        <Typography variant='h6' className={classes.folderTitle}>
          {name}
        </Typography>
        {description}
        {/* classifications */}
      </div>

      {folderContent}
    </>
  );
}
