import * as React from 'react';

import { connect } from 'react-redux';
import { connect as refetchConnect, PromiseState } from 'react-refetch';

import { withRouter } from 'react-router-dom';
import { type RouteComponentProps } from 'react-router';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { getPublicImage } from '@catalogit/common/lib/utils/media-utils.js';

import { withHUBContext, type HUBContextProps } from '../hub-context.js';

import type {
  HUBThunkDispatch,
  IStoreState,
  IAccountState,
  IClassification
} from '../types/store.js';

import { getAccounts } from '../actions/account.js';

import PageHeader, { paddingTop } from '../components/page-header.js';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      ...paddingTop(theme)
    },

    header: {
      margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(3)}px`,

      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(5)
      }
    },

    cards: {
      display: 'flex',
      flexWrap: 'wrap',
      boxSizing: 'border-box',

      padding: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`
      }
    },

    classificationCard: {
      width: '100%',
      height: '10em',
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        width: '33.33%',
        height: '15em'
      }
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

interface IReduxProps {
  accountState: IAccountState;
  dispatch: HUBThunkDispatch;
}

interface IRefetchProps {
  classificationsFetch?: PromiseState<IClassification[]>;
}

interface IState {
  search: string;
}

type IPropsType = JSSProps &
  IReduxProps &
  RouteComponentProps<any> &
  IRefetchProps &
  HUBContextProps;

class Classifications extends React.Component<IPropsType, IState> {
  constructor(props: IPropsType, context: any) {
    super(props, context);

    this.state = {
      search: ''
    };
  }

  componentDidMount() {
    const { accountState, xCITOrigin } = this.props;
    if (accountState.size <= 1) {
      this.props.dispatch(getAccounts(xCITOrigin));
    }
  }

  handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    this.props.history.push(`/classifications/${encodeURIComponent(e.currentTarget.dataset.id!)}`, {
      back: '/classifications'
    });
  };

  handleSearch = (search: string) => {
    if (search) {
      this.props.history.push(`/classifications/search/${encodeURIComponent(search)}`, {
        from: 'classifications',
        back: '/classifications'
      });
    }
  };

  render() {
    const { classificationsFetch, classes, theme } = this.props;
    if (!classificationsFetch) {
      return null;
    }

    let content;

    if (classificationsFetch.pending) {
      content = <div style={{ margin: theme.spacing(2) }}>loading</div>;
    } else if (classificationsFetch.rejected) {
      content = <div>{`Errored: ${classificationsFetch.reason}`}</div>;
    } else {
      content = (
        <div className={classes.cards}>
          {classificationsFetch.value.map(({ classification, total, media }) => {
            const backgroundImage = media ? `url('${getPublicImage(media).path}')` : undefined;

            return (
              <div
                key={classification}
                data-id={classification}
                onClick={this.handleClick}
                className={classes.classificationCard}
                style={{}}
              >
                <div
                  style={{
                    marginRight: theme.spacing(2),
                    height: '100%',
                    maxHeight: '100%',
                    padding: theme.spacing(2),
                    border: '1px solid #ddd',
                    borderRadius: theme.spacing(4),
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    flexGrow: 0,
                    backgroundImage,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.common.white
                  }}
                >
                  <Typography variant='h6' style={{ color: theme.palette.common.white }}>
                    {classification}
                  </Typography>
                  <Typography
                    variant='body2'
                    style={{
                      color: theme.palette.common.white,
                      fontWeight: 'bold'
                    }}
                  >
                    {`${total} items`}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <>
        <Helmet>
          <title>Classifications - CatalogIt HUB</title>
        </Helmet>

        <PageHeader onSearch={this.handleSearch} backNav='/' variant='normal' />

        <div className={classes.content}>
          <Typography variant='h4' className={classes.header}>
            All Classifications
          </Typography>
          {content}
        </div>
      </>
    );
  }
}

export default withHUBContext(
  withStyles(styles, { withTheme: true })(
    refetchConnect((props) => ({
      classificationsFetch: `${(props as any).apiEndpoint}/api/public/classifications`
    }))(
      withRouter(
        connect((state: IStoreState) => ({
          accountState: state.account
        }))(Classifications as any)
      )
    )
  )
);
